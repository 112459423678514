$brand-primary-1: #e6f0fe;
$brand-primary-3: #99c3f9;
$brand-primary-5: #3386f3;
$brand-primary-6: #0068f0;
$brand-primary-7: #004eb4;

$gray-1: #ffffff;
$gray-2: #f6f7f8;
$gray-3: #ebedf1;
$gray-4: #d8dbe2;
$gray-5: #b1b7c5;
$gray-6: #9da5b7;
$gray-7: #768190;
$gray-8: #546274;
$gray-9: #27292e;
$gray-10: #1f2124;

$white: #fff;
$black: #000000;

$green-1: #e6f8e8;
$green-3: #9ae0a1;
$green-5: #35c143;
$green-6: #03b114;
$green-7: #02850f;

$yellow-1: #fffbe6;
$yellow-3: #ffeb99;
$yellow-5: #ffd733;
$yellow-6: #ffcd00;
$yellow-7: #bf9a00;

$red-1: #fff1e6;
$red-3: #ffc399;
$red-4: #ff7875;
$red-5: #ff8733;
$red-6: #ff6900;
$red-7: #bf4f00;

$orange-1: #fff7e6;
$orange-3: #ffdd99;
$orange-5: #ffbb33;
$orange-6: #ffaa00;
$orange-7: #bf8000;

$blue-1: #e6f0fe;
$blue-3: #99c3f9;
$blue-5: #3386f3;
$blue-6: #0068f0;
$blue-7: #004eb4;

$magenta-6: #ef487c;

$app-background: $gray-3;
$header-background: $gray-1;
$light-background: $gray-1;

$primary: $brand-primary-6;
$secondary: $brand-primary-5;
$ternary: $magenta-6;

:export {
  appBackground: $app-background;
  headerBackground: $header-background;
  lightBackground: $light-background;

  primary: $primary;
  secondary: $secondary;
  ternary: $ternary;

  brandPrimary1: $brand-primary-1;
  brandPrimary3: $brand-primary-3;
  brandPrimary5: $brand-primary-5;
  brandPrimary6: $brand-primary-6;
  brandPrimary7: $brand-primary-7;

  gray1: $gray-1;
  gray2: $gray-2;
  gray3: $gray-3;
  gray4: $gray-4;
  gray5: $gray-5;
  gray6: $gray-6;
  gray7: $gray-7;
  gray8: $gray-8;
  gray9: $gray-9;
  gray10: $gray-10;

  white: $white;
  black: $black;

  green1: $green-1;
  green3: $green-3;
  green5: $green-5;
  green6: $green-6;
  green7: $green-7;

  yellow1: $yellow-1;
  yellow3: $yellow-3;
  yellow5: $yellow-5;
  yellow6: $yellow-6;
  yellow7: $yellow-7;

  red1: $red-1;
  red3: $red-3;
  red4: $red-4;
  red5: $red-5;
  red6: $red-6;
  red7: $red-7;

  orange1: $orange-1;
  orange3: $orange-3;
  orange5: $orange-5;
  orange6: $orange-6;
  orange7: $orange-7;

  blue1: $blue-1;
  blue3: $blue-3;
  blue5: $blue-5;
  blue6: $blue-6;
  blue7: $blue-7;
}
