@use 'src/scss/colors.scss' as colorsFile;

.previewReportWrapper {
  display: flex;
  width: 100%;
  height: 56px;
  background-color: colorsFile.$gray-10;
  padding: 0 80px;
  justify-content: space-between;
  border: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.24);
}

.backButton {
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    color: colorsFile.$gray-5;
    margin-left: 10px;
  }
}

.rightSideWrapper {
  display: flex;
  align-items: center;
}

.infoText {
  color: white;
  margin-left: 8px;
}

.sideBarButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  border-radius: 2px;
  color: white;
  background-color: colorsFile.$brand-primary-6;
  cursor: pointer;
  border: none;
  margin-left: 26px;
  font-size: 14px;

  &:focus,
  &:hover {
    background-color: colorsFile.$brand-primary-5;
    color: white !important;
  }

  &:disabled:hover {
    background-color: colorsFile.$gray-5;
  }
}

.borderTransparent {
  border-color: transparent !important;
}
