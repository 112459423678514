@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600&display=swap');

@font-face {
  font-family: 'Segoe UI';
  src: url('assets/fonts/SegoeUI.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('assets/fonts/SegoeUI-SemiBold.woff2') format('woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('assets/fonts/SegoeUI-Bold.woff2') format('woff2');
  font-weight: 700;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Needed to match antd v4 styles
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;

  .ant-btn {
    box-shadow: none;
    text-shadow: none;
  }
}

// Needed to match antd v4 styles
*,
:after,
:before {
  box-sizing: border-box;
}

.anticon {
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0;
}
