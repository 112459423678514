@use 'sass:map';
@use 'src/scss/colors.scss' as colorsFile;
@use 'src/scss/fonts.scss' as fontsFile;
@import 'src/scss/responsive.scss';

.previewReportContainer {
  width: 100%;
  height: 100vh;
  background-color: colorsFile.$gray-7;
}

.previewReportContentWrapper {
  display: flex;
  position: absolute;
  top: 56px;
  bottom: 0;
  left: 0;
  right: 0;
}

.previewReportContentContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}